import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { basePathConfig } from "../../config";
import errorHandler from "../error/errorHandler";
// Thunks
// fetch all Custom Webhook Data
export const fetchAllCustomWebhook = createAsyncThunk(
  "CustomWebhook/fetch",
  async () => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.get(
          `${basePathConfig.baseURL}/custom_webhook_configuration/account/${accountID}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Add New Custom Webhook
export const addCustomWebhook = createAsyncThunk(
  "customWebhook/add",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      const userID = localStorage.getItem("atur");
      const payloadData = {
        account_id: accountID,
        webhook_name: payload.webhook_name,
        url: payload.webhook_url,
        user_id: userID,
      };
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.post(
          `${basePathConfig.baseURL}/custom_webhook_configuration`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Edit New Custom Webhook
export const editCustomWebhook = createAsyncThunk(
  "customWebhook/edit",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      const userID = localStorage.getItem("atur");
      const payloadData = {
        _id: payload.id,
        account_id: accountID,
        webhook_name: payload.webhook_name,
        url: payload.webhook_url,
        user_id: userID,
      };

      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.put(
          `${basePathConfig.baseURL}/custom_webhook_configuration`,
          payloadData,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

// Delete New Custom Webhook
export const deleteCustomWebhook = createAsyncThunk(
  "customWebhook/delete",
  async (payload) => {
    try {
      const bearer_token = `Bearer ${localStorage.getItem("accessToken")}`;
      const accountID = localStorage.getItem("atua");
      if (!!bearer_token && !!accountID) {
        const config = {
          headers: {
            Authorization: bearer_token,
          },
        };
        const res = await axios.delete(
          `${basePathConfig.baseURL}/custom_webhook_configuration/webhook/${payload.id}`,
          config
        );
        return await res.data;
      } else {
        return { message: "Unauthorized", status: 401 };
      }
    } catch (error) {
      var ErrorData = await errorHandler(error);
      return await ErrorData;
    }
  }
);

export const STATUSES = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  LOADING: "loading",
});

const initialState = {
  data: [],
  addData: [],
  editData: [],
  deleteData: [],
  status: STATUSES.SUCCESS,
};

export const customWebhookSlice = createSlice({
  name: "customWebhook",
  initialState,
  reducers: {
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllCustomWebhook.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(fetchAllCustomWebhook.fulfilled, (state, action) => {
        state.data = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(fetchAllCustomWebhook.rejected, (state, action) => {
        state.status = STATUSES.ERROR;
      })
      .addCase(addCustomWebhook.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(addCustomWebhook.fulfilled, (state, action) => {
        state.addData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(addCustomWebhook.rejected, (state, action) => {
        state.addData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(editCustomWebhook.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(editCustomWebhook.fulfilled, (state, action) => {
        state.editData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(editCustomWebhook.rejected, (state, action) => {
        state.editData = [];
        state.status = STATUSES.ERROR;
      })
      .addCase(deleteCustomWebhook.pending, (state, action) => {
        state.status = STATUSES.LOADING;
      })
      .addCase(deleteCustomWebhook.fulfilled, (state, action) => {
        state.deleteData = action.payload;
        state.status = STATUSES.SUCCESS;
      })
      .addCase(deleteCustomWebhook.rejected, (state, action) => {
        state.deleteData = [];
        state.status = STATUSES.ERROR;
      });
  },
});
export const { setCannedResponses, setStatus, setAddData, reset } =
  customWebhookSlice.actions;
export default customWebhookSlice.reducer;
