import React from "react";
import styled from "@emotion/styled";

import {
  Alert as MuiAlert,
  AlertTitle,
  //Checkbox,
  IconButton,
  InputBase,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  //TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
  // FormControlLabel,
  // Switch,
  Button,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { settingsMenu } from "../../../../../redux/slices/settings";
import { isValidToken } from "../../../../../utils/jwt";
import { useNavigate } from "react-router-dom";
import {
  fetchLogOut,
  fetchLoginLogOut_Socket,
  logout,
} from "../../../../../redux/slices/login";
import { fetchAllCustomWebhook } from "../../../../../redux/slices/customWebhookSlice";
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Paper = styled(MuiPaper)(spacing);
const Alert = styled(MuiAlert)(spacing);
const role = localStorage.getItem("atur");

const Search = styled.div`
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #e7f2ff;
`;

const Spacer = styled.div`
  flex: 1 1 20%;
`;

function createData(srno, id, webhook_name, url) {
  return { srno, id, webhook_name, url };
}
var rows = [];
var rowsData = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => ({
    el,
    index,
  }));
  stabilizedThis.sort((a, b) => {
    const order = comparator(a.el, b.el);
    if (order !== 0) return order;
    return a.index - b.index;
  });
  return stabilizedThis.map((element) => element.el);
}

const headCells = [
  {
    id: "webhook_name",
    numeric: false,
    disablePadding: true,
    label: "Name",
    width: 150,
  },
  {
    id: "url",
    numeric: false,
    disablePadding: true,
    label: "Webhook Url",
    width: 400,
  },
  !!role &&
    role === "638f1029a1c0997b2085c2a2" && {
      id: "action",
      numeric: false,
      disablePadding: true,
      label: "Actions",
      width: 150,
    },
];

const EnhancedTableHead = (props) => {
  const { order, orderBy, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sx={{ width: headCell.width ? `${headCell.width}px` : "auto" }} // Ensure width is applied
            align={headCell.numeric ? "right" : "left"}
            padding="checkbox"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ customWebhook, status, dispatch }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(2);
  const [search, setSearch] = React.useState("");
  const accessToken = window.localStorage.getItem("accessToken");
  const navigate = useNavigate();
  React.useEffect(() => {
    setDense(true);
    setRowsPerPage(2);
  }, []);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleEdit = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "custom_webhook_edit",
          listData: row,
        })
      );
    }
  };

  const handleDelete = (row) => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "custom_webhook_delete",
          listData: row,
        })
      );
    }
  };

  const handleAdd = () => {
    if (!isValidToken(accessToken)) {
      dispatch(fetchLogOut());
      dispatch(fetchLoginLogOut_Socket());
      dispatch(logout());
      navigate("/");
    } else {
      dispatch(
        settingsMenu({
          list: "custom_webhook_add",
          listData: [],
        })
      );
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  let emptyRows = rowsPerPage - (rowsPerPage + 1);
  rowsData =
    !!customWebhook && !!customWebhook.data
      ? customWebhook.data.map((item, i) =>
          createData(++i, item._id, item.webhook_name, item.url)
        )
      : [];
  rows =
    search === ""
      ? rowsData
      : rowsData.filter(
          (i) =>
            i.webhook_name.toLowerCase().includes(search.toLowerCase()) ||
            i.url.toLowerCase().includes(search.toLowerCase())
        );

  const searchFilter = (e) => {
    setPage(0);
    setSearch(e.target.value);
  };
  return (
    <div>
      <Paper>
        <Toolbar>
          <div>
            <Typography variant="h6" id="tableTitle">
              Custom Webhook List
            </Typography>
          </div>
          <Spacer />
          <div>
            {!!role && role === "638f1029a1c0997b2085c2a2" && (
              <Button
                mr={2}
                variant="contained"
                color="primary"
                disabled={rows.length >= 2 ? true : false}
                style={{ marginRight: "5px" }}
                onClick={() => {
                  handleAdd();
                }}
              >
                Add new webhook
                <AddIcon />
              </Button>
            )}
          </div>
        </Toolbar>
        <Search>
          <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search"
            //onChange={(e) => setSearch(e.target.value)}
            onChange={(e) => {
              searchFilter(e);
            }}
          />
        </Search>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        <TableContainer>
          {!!status && status === "loading" && (
            <CircularProgress m={2} color="primary" />
          )}
          {!!customWebhook &&
            !!customWebhook.status &&
            parseInt(customWebhook.status) !== 200 && (
              <Alert mb={4} severity="error">
                <AlertTitle>
                  Error: (status-code : {customWebhook.status})
                </AlertTitle>
                {customWebhook.message} — <strong>check it out!</strong>
              </Alert>
            )}

          {!!status && status === "error" && (
            <Alert mb={4} severity="error">
              <AlertTitle>Error: </AlertTitle>
              <strong>Something went wrong</strong>
            </Alert>
          )}

          <Table
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!!rows &&
                stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.webhook_name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) =>
                          handleClick(event, row.webhook_name)
                        }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        selected={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          //padding="none"
                          padding="checkbox"
                        >
                          {row.webhook_name}
                        </TableCell>
                        <TableCell align="left">{row.url}</TableCell>
                        {!!role && role === "638f1029a1c0997b2085c2a2" && (
                          <TableCell align="left">
                            <EditIcon
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "5px", cursor: "pointer" }}
                              onClick={() => {
                                handleEdit(row);
                              }}
                            />
                            <DeleteIcon
                              variant="contained"
                              color="primary"
                              style={{ marginRight: "5px", cursor: "pointer" }}
                              onClick={() => {
                                handleDelete(row);
                              }}
                            />
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}

const CustomWebhookTable = () => {
  const dispatch = useDispatch();

  const { data: customWebhook, status } = useSelector(
    (state) => state.customWebhook
  );
  React.useEffect(() => {
    dispatch(fetchAllCustomWebhook());
  }, [dispatch]);

  return (
    <React.Fragment>
      <Grid container spacing={3} style={{ padding: "10px" }}>
        <Grid item xs={12}>
          <EnhancedTable
            customWebhook={customWebhook}
            status={status}
            dispatch={dispatch}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default CustomWebhookTable;
