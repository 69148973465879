import React from "react";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import {
  Alert as MuiAlert,
  AlertTitle,
  Box,
  Typography as MuiTypography,
  Divider as MuiDivider,
  Grid,
  CircularProgress as MuiCircularProgress,
} from "@mui/material";

import WebhookIcon from "@mui/icons-material/Webhook";
import { useDispatch, useSelector } from "react-redux";
import { fetchAccount } from "../../../../redux/slices/account";
import CustomWebhookTable from "./customwebhook/CustomWebhookTable";
//import UnAuthenticated from "../../UnAuthenticated";
const Alert = styled(MuiAlert)(spacing);
const CircularProgress = styled(MuiCircularProgress)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Typography = styled(MuiTypography)(spacing);

const TitleTypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
  padding-bottom: 12px;
  color: ${(props) => props.theme.sidebar.header.color};
`;
const Wrapper = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;
const Ptypography = styled(Typography)`
  margin-top: 15px;
  padding-right: 20px;
`;

const CustomWebhook = () => {
  const dispatch = useDispatch();
  const { data: account, status } = useSelector((state) => state.account);
  React.useEffect(() => {
    dispatch(fetchAccount());
  }, [dispatch]);
  return (
    <>
      <Box
        style={{
          backgroundColor: "#F9F9F9",
          height: "92vh",
          borderRight: "1px solid rgba(0, 0, 0, 0.12)",
        }}
      >
        <Wrapper>
          <TitleTypography variant="h4">
            <WebhookIcon /> Custom Webhook Integrations
          </TitleTypography>
        </Wrapper>
        <Divider my={2} sx={{ borderBottomWidth: 2 }} />
        {!!status && status === "loading" && (
          <CircularProgress m={2} color="primary" />
        )}
        {!!account && !!account.status && parseInt(account.status) !== 200 && (
          <Alert mb={4} severity="error">
            <AlertTitle>Error: (status-code : {account.status})</AlertTitle>
            {account.message} — <strong>check it out!</strong>
          </Alert>
        )}

        {!!status && status === "error" && (
          <Alert mb={4} severity="error">
            <AlertTitle>Error: </AlertTitle>
            <strong>Something went wrong</strong>
          </Alert>
        )}
        <Wrapper>
          <Grid container>
            <Grid item xs={12} lg={9}>
              <Box
                style={{
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                  borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                }}
              >
                <Grid container>
                  <Grid item xs={12} lg={12}>
                    <CustomWebhookTable />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3}>
              <Box
                style={{
                  padding: "10px",
                  backgroundColor: "#F9F9F9",
                  height: "92vh",
                }}
              >
                <Typography gutterBottom variant="h5" component="h2">
                  Custom Webhook
                </Typography>
                <Ptypography component="p">
                  Webhooks are HTTP callbacks that can be defined for each
                  account. They are triggered by events such as resolving a
                  conversation in Live-Agent. You can create more than one
                  webhook for this account.
                </Ptypography>
                <Ptypography component="p">
                  To create a webhook, click on the <b>Add New Webhook</b>{" "}
                  button. You can also delete an existing webhook by clicking on
                  the Delete button. <b>Note: </b> Only admins have the
                  permission to perform these actions. The maximum limit is 2
                  webhooks.
                </Ptypography>
              </Box>
            </Grid>
          </Grid>
        </Wrapper>
      </Box>
    </>
  );
};

export default CustomWebhook;
